import { useState, useEffect } from 'react';
import axios from 'axios';

function App() {
  const [value, setValue] = useState('');
  const [tasks, setTasks] = useState([]); 
  const apiUrl = 'http://127.0.0.1:8000/api/Tareas'; 
  useEffect(() => {
    axios.get(apiUrl)
      .then(response => {
        setTasks(response.data.data); 
      })
      .catch(error => {
        console.error('Hubo un error al obtener las tareas:', error);
      });
  }, []); 

  const handleOnChange = (e) => {
    setValue(e.target.value);
  };

  const handleAddTask = () => {
    if (value.trim()) { 
      const newTask = { detalle: value, estados_id: 1 }; 

      axios.post(apiUrl, newTask)
        .then(response => {
          setTasks([...tasks, response.data.tareas]); 
          setValue(''); 
        })
        .catch(error => {
          console.error('Hubo un error al agregar la tarea:', error);
        });
    }
  };

  const handleDeleteTask = (id) => {
    axios.delete(`${apiUrl}/${id}`)
      .then(response => {
        setTasks(tasks.filter(task => task.id !== id)); 
      })
      .catch(error => {
        console.error('Hubo un error al eliminar la tarea:', error);
      });
  };

  return (
    <div className="container mt-4">
      {}
      <h1 className="text-center mb-4">Base de datos</h1>

      <div className="input-group mb-3 d-flex justify-content-center">
        {}
        <input
          type="text"
          className="form-control w-25" 
          value={value}
          onChange={handleOnChange}
          placeholder="Escriba La Tarea"
          maxLength={50} 
        />
        <button
          className="btn btn-primary"
          onClick={handleAddTask}
        >
          Agregar tarea
        </button>
      </div>

      <ul className="list-group">
        {tasks.map((task) => (
          <li key={task.id} className="list-group-item d-flex justify-content-between align-items-center">
            {task.detalle} {}
            <button
              className="btn btn-danger btn-sm"
              onClick={() => handleDeleteTask(task.id)}
            >
              Eliminar
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default App;
